import Vue from 'vue';

Vue.component('cpo-design', () => import('../../views/design.vue'));
Vue.component('cpo-order', () => import('../../views/order.vue'));
Vue.component('cpo-order-manage', () => import('../../views/orderManage.vue'));
Vue.component('cpo-user-manage', () => import('../../views/userManage.vue'));

import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../css/normalize.css';
import '../css/common.css';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
/*
 *  common: 通用属性
 */
const stateObj = {
    // 登录用户信息
    user: {
        token: '',
        name: '',
        roleId: ''
    },
    module: 'design'
};

export default {
    namespaced: true,
    state: JSON.parse(JSON.stringify(stateObj)),
    mutations: {
        updateUser(state, user) {
            Object.assign(state.user, user);
        },
        updateModule(state, module) {
            state.module = module;
        },
        reset(state) {
            Object.assign(state.user, stateObj.user);
            state.module = stateObj.module;
        }
    },
    actions: {
        login({commit}, user) {
            commit('updateUser', user);
        },
        changeModule({commit}, module) {
            commit('updateModule', module);
        },
        logout({commit}) {
            commit('reset');

            // 清空 sessionStorage
            window.sessionStorage.removeItem('store');
        }
    }
};

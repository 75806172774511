const md5 = require('md5');

/**
 * md5
 * @param str
 * @param salt
 * @returns {*}
 */
export const MD5 = (str, salt) => {
    if (salt) {
        str += salt;
    }
    return md5(str);
};

/**
 * sessionStorage
 * @param key
 * @param value
 * @returns {string}
 */
export const sessionStorage = (key, value) => {
    if (!window.sessionStorage) {
        console.error('sorry, your browser\'s sessionStorage unsupported!');
        return 'unsupported';
    }

    if (value === null || value === undefined) {
        return window.sessionStorage.getItem(key);
    } else {
        if (typeof value === "object") {
            window.sessionStorage.setItem(key, JSON.stringify(value));
        } else if (typeof value === "function") {
            window.sessionStorage.setItem(key, value.toString());
        } else {
            window.sessionStorage.setItem(key, value);
        }
    }
};

/**
 * 日期格式化
 * @param date
 * @param fmt
 * @returns {*}
 */
export const dateFormat = (date, fmt) => {
    if (!date) return null;
    date = new Date(date);

    let o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        'S': date.getMilliseconds() // 毫秒
    }

    if (!fmt) fmt = "yyyy-MM-dd hh:mm:ss";
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        }
    }
    return fmt;
}

export const type2ClothingTypeArr = (type) => {
    let clothingTypeArr = [];
    if (type !== '') {
        switch (type) {
            case '2pcs':
                clothingTypeArr.push('C', 'P');
                break;
            case "3pcs":
                clothingTypeArr.push('C', 'P', 'V');
                break;
            case "jacket":
                clothingTypeArr.push('C');
                break;
            case "pant":
                clothingTypeArr.push('P');
                break;
            case "vest":
                clothingTypeArr.push('V');
                break;
            default:
                console.error('type[%s] match fail', type);
        }
    }
    return clothingTypeArr;
};
module.exports = {
    setting: {
        url: {
            web: 'https://www.yinfusuits.com',
            server: 'https://server.yinfusuits.com/'
        }
    },
    salt: {
        password: 'custom-platform'
    }
}
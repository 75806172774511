/**
 * Ajax请求：axios实现
 */
import axios from 'axios';

const service = axios.create({
    // 设置超时时间
    timeout: 60000,
    headers: {'content-type': 'application/x-www-form-urlencoded'}
});

/**
 * 请求记录集合：[{url, cancel}]
 * 注：仅处理包含 page、list 的请求
 */
const reqList = [];

/**
 * 请求前拦截
 * 用于处理需要在请求前的操作
 */
service.interceptors.request.use(cfg => {
    cfg.headers['Authorization'] = window.vm.$store.state.common.user.token;

    let cancel;
    // 设置cancelToken对象
    cfg.cancelToken = new axios.CancelToken(function (canceler) {
        cancel = canceler;
    });
    // 相同的请求时，取消上个未完成请求
    cancelPreviousRequest(cfg.url, cancel);

    return cfg;
}, (err) => {
    return Promise.reject(err);
});

/**
 * 请求响应拦截
 * 用于处理需要在请求返回后的操作
 */
service.interceptors.response.use(
    resp => {
        releaseRequest(resp.config.url);

        const responseCode = resp.status;
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        if (responseCode === 200) {
            return Promise.resolve(resp);
        }
        // 否则的话抛出错误
        else {
            // 弹出错误信息
            window.vm.$bvToast.toast(`服务器发生错误，返回状态码：${responseCode}.`, {
                title: '系统提示',
                variant: 'danger',
                solid: true
            });
            return Promise.reject(resp);
        }
    },
    err => {
        // 错误信息输出控制台
        console.error(err);

        if (!axios.isCancel(err)) {
            releaseRequest(err.config.url);

            // 服务器返回不是 2 开头的情况，会进入这个回调
            if (err.response) {
                let errResponse = err.response;
                // 可以根据后端返回的状态码进行不同的操作
                const responseCode = errResponse.status;
                switch (responseCode) {
                    // 401：未登录|token过期
                    // 403: 服务器拒绝请求
                    case 401:
                    case 403:
                        // 弹出错误信息
                        window.vm.$bvToast.toast(`服务器拒绝访问[${errResponse.data}]，请退出后重新登录.`, {
                            title: '系统提示',
                            variant: 'danger',
                            solid: true
                        });
                        break;
                    // 404：请求不存在
                    case 404:
                        // 弹出错误信息
                        window.vm.$bvToast.toast('请求服务地址不存在', {
                            title: '系统提示',
                            variant: 'danger',
                            solid: true
                        });
                        break;
                    // 500：服务器内部错误
                    case 500:
                        // 弹出错误信息
                        window.vm.$bvToast.toast('服务器内部错误', {
                            title: '系统提示',
                            variant: 'danger',
                            solid: true
                        });
                        break;
                    // 其他错误，直接抛出错误提示
                    default:
                        // 弹出错误信息
                        window.vm.$bvToast.toast('服务响应错误码[' + responseCode + ']，错误信息[' + JSON.stringify(errResponse.data) + '].', {
                            title: '系统提示',
                            variant: 'danger',
                            solid: true
                        });
                }
            } else if (err.request) {
                // 弹出错误信息
                window.vm.$bvToast.toast('服务响应错误码[' + err.request.status + '].', {
                    title: '系统提示',
                    variant: 'danger',
                    solid: true
                });
            } else if (err.status) {
                // 弹出错误信息
                window.vm.$bvToast.toast('服务响应错误码[' + err.status + ']，错误信息[' + err.message + '].', {
                    title: '系统提示',
                    variant: 'danger',
                    solid: true
                });
            } else if (err.message) {
                // 弹出错误信息
                window.vm.$bvToast.toast(err.message, {
                    title: '系统提示',
                    variant: 'danger',
                    solid: true
                });
            } else {
                window.vm.$bvToast.toast('服务响应失败，请稍后重试', {
                    title: '系统提示',
                    variant: 'danger',
                    solid: true
                });
            }
        }

        return Promise.reject(err);
    });

export default service;

/**
 * 请求处理：
 * 1、已存在相同的请求，取消上个未完成请求
 * 2、不存在，记录请求
 * @param url
 * @param cancel
 */
function cancelPreviousRequest(url, cancel) {
    if (url.indexOf('page') <= -1 && url.indexOf('list') <= -1) {
        return;
    }

    let req;
    for (let i = 0; i < reqList.length; i++) {
        req = reqList[i];
        if (req.url === url) {
            req.cancel('请求被中断: ' + url);
            return;
        }
    }
    reqList.push({
        url, cancel
    });
}

/**
 * 释放请求：
 * 请求记录集合中删除请求记录
 * @param url
 */
function releaseRequest(url) {
    if (url.indexOf('page') <= -1 || url.indexOf('list') <= -1) {
        return;
    }

    let req;
    for (let i = 0; i < reqList.length; i++) {
        req = reqList[i];
        if (req.url === url) {
            reqList.splice(i, 1);
            break;
        }
    }
}

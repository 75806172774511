import Vue from 'vue';
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';

// rule.
extend('required', {
    validate: value => value !== '',
    computesRequired: true,
    message: '[{_field_}] is required.'
});
extend('number-2', {
    validate: value => /^[0-9]+(\.[0-9]{1,2})?$/.test(value),
    message: '[{_field_}] is not number.'
});

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
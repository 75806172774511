import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login.vue')
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: () => import('../views/welcome.vue')
    },
    {
        path: '/design',
        name: 'Design',
        component: () => import('../views/design.vue')
    },
    {
        path: '/manage',
        name: 'Manage',
        component: () => import('../views/userManage.vue')
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (store.state.common.user.token) {
        if (to.path === '/' || to.path === '/login') {
            next({path: '/welcome'});
            return;
        }
    } else {
        if (to.path !== '/' && to.path !== '/login') {
            next({path: '/login'});
            return;
        }
    }

    next();
});

export default router;

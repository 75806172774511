import qs from 'qs';
import service from './service';
import {setting} from "../assets/js/config";

export default {
    get(url, params = {}) {
        return new Promise((resolve, reject) => {
            service.get(process(url), {
                params: params
            })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    post(url, data = {}) {
        return new Promise((resolve, reject) => {
            service.post(process(url), qs.stringify(data))
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    put(url, data = {}) {
        return new Promise((resolve, reject) => {
            service.put(process(url), qs.stringify(data))
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    delete(url, params = {}) {
        return new Promise((resolve, reject) => {
            service.delete(process(url), {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

function process(url) {
    if (!url.startsWith('http://') && !url.startsWith('https://') && !url.startsWith('/proxy')) {
        return setting.url.server + url;
    }
    return url;
}

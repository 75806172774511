import Vue from 'vue';
import Vuex from 'vuex';
import {sessionStorage} from "../assets/js/util";
// 子模块
import common from './common';

Vue.use(Vuex);

let store = new Vuex.Store({
    modules: {
        common
    }
});

// 页面创建时，读取sessionStorage中需同步存储store的状态信息
if (sessionStorage("store")) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage("store"))));
}

// 页面离开前，将store中状态信息同步存储至sessionStorage
// 解决问题：单页面刷新会清空store状态信息
window.addEventListener("beforeunload", () => {
    sessionStorage("store", store.state);
});

export default store;
